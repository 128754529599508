<template>
  <main class="relative pt-8">
    <div v-if="type === 'add'" class="flex flex-col gap-4 items-center my-4">
      <h2 class="font-bold text-xl text-dark-800">HMO Registration</h2>
    </div>
    <!-- <hr
      v-if="type === 'add'"
      class="mt-6 mb-5 border-0.5 border-dividerColor text-dividerColor"
    /> -->
    <div
      v-show="!employer && type === 'add'"
      class="flex justify-center items-center gap-x-2"
    >
      <span class="text-sm text-dark-800 font-bold"
        >Already-registered employee?</span
      >
      <div class="flex items-center">
        <label for="" class="text-dark-800 text-sm font-medium">Yes</label>
        <input class="mx-4 my-2" type="checkbox" name="" id="" />
      </div>

      <div class="flex items-center">
        <label for="" class="text-dark-800 text-sm font-medium">No</label>
        <input class="mx-4 my-2" type="checkbox" name="" id="" />
      </div>
    </div>

    <form
      @submit.prevent="addHmo"
      autocomplete="on"
      class="flex flex-col text-left mt-7"
    >
      <div class="flex flex-col gap-1 px-3 md:px-12 lg:px-8 xl:px-10">
        <!-- <label for="pension" class="text-dark-800 text-sm font-medium"
          >Select HMO provider</label
        > -->
        <easiProviderSelect
          label="Select HMO provider"
          required
          class="mt-1 mb-2"
          @update="args.hmo.provider = $event"
          :value="args.hmo.provider"
          :options="HmoProviders"
        />

        <!-- <label for="duration" class="text-dark-800 text-sm font-medium"
          >Duration
        </label>
        <easiSelectInput2
          required
          class="mt-1 mb-2"
          @update="args.hmo.duration = $event"
          :value="args.hmo.duration"
          :options="[
            {
              label: 'Weekly',
              value: 'WEEKLY',
            },
            {
              label: 'Monthly',
              value: 'MONTHLY',
            },

            {
              label: 'Annually',
              value: 'ANNUALLY',
            },
            {
              label: 'One-Time',
              value: 'ONE_TIME',
            },
          ]"
        /> -->
        <!-- <label for="plan" class="text-dark-800 text-sm font-medium"
          >Select Plan
        </label> -->
        <easiSelectInput2
          required
          placeholder="Select plan"
          class="mt-1"
          @update="args.hmo.plan = $event"
          :value="args.hmo.plan"
          :options="[
            {
              label: 'Bronze',
              value: 'BRONZE',
            },
            {
              label: 'Silver',
              value: 'SILVER',
            },
            {
              label: 'Gold',
              value: 'GOLD',
            },
            {
              label: 'Gold Plus',
              value: 'GOLD_PLUS',
            },
            {
              label: 'Platinum',
              value: 'PLATINUM',
            },
          ]"
        />

        <!-- <label for="hmoamount" class="text-dark-800 text-sm font-medium"
          >Amount
        </label> -->
        <easiTextInput
          placeholder="Amount"
          type="text"
          :format="true"
          name="hmoamount"
          v-model="args.hmo.amount"
          class="mb-4"
          required
        ></easiTextInput>

        <div class="w-full mt-4">
          <p class="text-dark-600 text-xs text-center font-medium">
            Add employee to register
          </p>
          <easiEmployeeBonus
            @update="args.employeeIds = $event"
            :value="args.employeeIds"
            :directorView="true"
            :options="createEmpList()"
            class="mt-1 mb-4"
          />
        </div>
      </div>

      <!-- <form class="flex items-center gap-4 justify-center mb-3" action="">
        <div>
          <label class="text-dark-800 font-normal text-xs mr-2" for="charge1"
            >Charge To Employee</label
          >
          <input
            type="radio"
            name="charge"
            id="charge1"
            value="EMPLOYEE"
            v-model="args.hmo.chargeTo"
          />
        </div>
        <div class="h-4 border bg-none border-gray w-0"></div>

        <div>
          <label class="text-dark-800 font-normal text-xs mx-2" for="charge2"
            >Charge To Employer</label
          >
          <input
            type="radio"
            id="charge2"
            name="charge"
            value="EMPLOYER"
            v-model="args.hmo.chargeTo"
          />
        </div>
      </form> -->

      <!-- <div class="flex items-center gap-4 justify-center mb-3" action="">
        <div>
          <label class="text-dark-800 font-normal text-xs mr-2" for="amount"
            >Charge To Employee</label
          >
          <input
            type="radio"
            name="percentage"
            id="percentage"
            :value="false"
            v-model="args.chargeHmoToEmployee"
          />
        </div>
        <div class="h-4 border bg-none border-gray w-0"></div>

        <div>
          <label class="text-dark-800 font-normal text-xs mx-2" for="percentage"
            >Charge To Employer</label
          >
          <input
            type="radio"
            name="percentage"
            id="percentage"
            :value="true"
            v-model="args.chargeHmoToEmployee"
            checked
          />
        </div>
      </div> -->

      <hr
        v-if="type === 'add'"
        class="mb-4 mx-8 border-0.5 border-dividerColor text-dividerColor"
      />
      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Submit
        </button>
      </div>

      <div
        v-if="type === 'edit' || edit === true"
        class="px-4 md:px-0 w-full my-6 flex md:flex-row flex-col md:justify-center md:justify-items-center md:place-items-center gap-5 md:items-center"
      >
        <easiButton
          @click="$router.go(-1)"
          type="button"
          color="secondary"
          variant="outlined"
          class="rounded-full bg-white w-full md:w-52"
          :block="true"
        >
          <span class="text-sm text-dark-800"> Cancel</span>
        </easiButton>
        <easiButton
          :loading="loading"
          :block="true"
          color="secondary"
          class="rounded-full w-full md:w-52"
        >
          <span class="text-sm"> Update </span>
        </easiButton>
      </div>

      <p
        @click="deleteWarning = true"
        v-show="type === 'edit' || edit === true"
        class="cursor-pointer text-center text-sm font-medium text-dark-800 my-4"
      >
        Delete HMO
      </p>
    </form>
    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to delete Life Insurance?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading"
              @click="deleteBonusOrDeduction"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import IndustryList from "@/utils/Industry";
import HmoProviders from "@/utils/HmoProviders";

import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import lgData from "@/utils/localGovt";
import Countries from "@/utils/countries";

import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { resetObj, processDate } = helperFunctions;
const props = defineProps({
  type: {
    type: String,
    default: "add",
  },
});

const emit = defineEmits(["registered", "back", "close"]);
const successMessage = ref("");
const updateSuccess = ref("");
const loading = ref(false);
const edit = ref(false);

const route = useRoute();
const employer = ref(true);
const industryData = IndustryList.map((el) => el.name);
const uploadFile = ref(null);
const localGovtData = ref([]);
const selectedState = computed(() => args.value.state);
const agreement = ref(true);
const useTaxOffice = ref(true);
const isExpatriate = ref(false);
const hasTaxPayerId = ref(false);
const args = ref({
  chargeHmoToEmployee: false,
  industry: "",
  title: "",
  gender: "",
  maritalStatus: "",
  state: "",
  lga: "",
  country: "",
  taxOffice: "",
  employeeIds: [],
  hmo: {
    provider: "",
    amount: 0,
    duration: "MONTHLY",
    plan: "BRONZE",
    chargeTo: "EMPLOYER",
  },
});
const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);
const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
queryEmployees();
const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
const countryData = computed(() => {
  let countryArray = [];
  countryArray = Countries.map((country) => {
    return country.name;
  });
  return countryArray;
});
watch(
  selectedState,
  (value, newValue) => {
    console.log(value, "New Value");
    const lgs = lgData.find(
      (lg) => lg.state.toLowerCase() === value.toLowerCase()
    );
    localGovtData.value =
      lgs &&
      lgs.lgas.map((lg) => {
        return {
          label: lg,
          value: lg,
        };
      });
  },
  { deep: true }
);
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  console.log(stateArray);
  return stateArray;
});
const today = ref("");
today.value = processDate();

function closeFN() {
  emit("registered");
}

async function addHmo() {
  loading.value = true;
  let payload = {
    hmo: {},
    benefits: ["HMO"],
    // employeeIds: [],
  };

  payload["hmo"]["amount"] = Number(args.value.hmo.amount);
  payload["hmo"]["provider"] = args.value.hmo.provider;
  payload["hmo"]["duration"] = args.value.hmo.duration;
  payload["hmo"]["plan"] = args.value.hmo.plan;

  // payload["employeeIds"] = args.value.employeeIds;

  // return true;

  try {
    let res = await mutate({
      endpoint: "AddCompliance",
      data: { input: payload, employeeIds: args.value.employeeIds },
      service: "PAYROLL",
    });
    if (res.success) {
      console.log(res);
      successMessage.value = res.message;
      // removeFiles.value = true;
      // uploadFile.value = null;
      // uploadProofOfAddress.value = null;
      loading.value = false;
      // await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    loading.value = false;
  }
}

function updateLg(event) {
  console.log(event);
  args.value.state = event;
}
</script>

<style>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
</style>
