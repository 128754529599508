<template>
  <main v-if="!addDirector" class="pt-8">
    <h2 class="capitalize text-center font-bold text-bodyDark text-xl">
      NSITF registration
    </h2>
    <div class="flex flex-col gap-4 items-center mt-6">
      <h2 class="font-bold text-sm text-dark-800 text-center">
        Kindly click the field to search and add <br />
        directors from your director’s list.
      </h2>
    </div>
    <hr
      class="invisible mt-3 mb-2 border-0.5 border-dividerColor text-dividerColor"
    />

    <form
      @submit.prevent="registerNsitf"
      autocomplete="on"
      class="flex flex-col"
    >
      <div class="px-3 md:px-8 text-left lg:px-8 xl:px-10">
        <!-- <label for="email" class="text-left text-dark-800 text-sm font-medium"
          >Select Director(s)
        </label> -->
        <easiEmployeeBonus
          @update="args.directors = $event"
          :value="args.directors"
          :options="founders"
          :forFounder="true"
          :directorView="true"
          placeHolder="Search director"
          class="mt-1 mb-1 text-left"
        />
      </div>
      <div class="px-3 md:px-12 text-left lg:px-8 xl:px-10">
        <div
          @click="addDirector = true"
          class="cursor-pointer border border-outlineGray rounded-full py-2 text-primary font-medium shadow-sm text-sm my-3 text-center"
        >
          Click here to add a new director not on the list
          <i class="fas fa-arrow-right ml-2"></i>
        </div>
        <hr
          class="mb-0 mt-4 border-0.5 border-dividerColor text-dividerColor"
        />
      </div>

      <div class="px-3 md:px-12 lg:px-8 xl:px-10 text-left">
        <!-- <label for="tin" class="text-dark-800 text-sm font-medium">TIN </label> -->
        <easiTextInput
          v-model="args.tin"
          placeholder="TIN"
          type="text"
          name="tin"
          class="mt-1 mb-8"
          required
        ></easiTextInput>
        <hr
          class="mb-0 mt-4 border-0.5 border-dividerColor text-dividerColor"
        />
        <!-- 
        <div class="my-4 flex items-center gap-x-10">
          <div class="flex gap-2 justify-center">
            <span class="text-sm text-left text-bodyDark font-bold"
              >Allow Staff List?
            </span>
          </div>

          <div
            class="flex cursor-pointer items-center gap-x-4 rounded-lg border border-outlineGray py-1 px-2 bg-newAsh text-newGray font-bold"
          >
            <span
              @click="staffList = true"
              class="text-xs"
              :class="
                staffList === true ? 'bg-white  p-1 rounded-md' : undefined
              "
              >YES</span
            >
            <span
              @click="staffList = false"
              class="text-xs"
              :class="
                staffList === false ? 'bg-white  p-1 rounded-md' : undefined
              "
              >NO</span
            >
          </div>
        </div>

        <div class="mb-4 flex items-center gap-x-10">
          <div class="flex gap-2 justify-center">
            <span class="text-sm text-left text-bodyDark font-bold"
              >Allow Staff Salary?
            </span>
          </div>

          <div
            class="flex cursor-pointer items-center gap-x-4 rounded-lg border border-outlineGray py-1 px-2 bg-newAsh text-newGray font-bold"
          >
            <span
              @click="staffSalary = true"
              class="text-xs"
              :class="
                staffSalary === true ? 'bg-white  p-1 rounded-md' : undefined
              "
              >YES</span
            >
            <span
              @click="staffSalary = false"
              class="text-xs"
              :class="
                staffSalary === false ? 'bg-white  p-1 rounded-md' : undefined
              "
              >NO</span
            >
          </div>
        </div>
        <hr
          class="mb-3 mt-2 border-0.5 border-dividerColor text-dividerColor"
        /> -->
      </div>

      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Submit
        </button>
      </div>
    </form>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>
  </main>

  <main v-else>
    <div class="text-left w-full">
      <AddDirectorComponent
        :addOnly="true"
        @close="addDirector = false"
        @previous="addDirector = false"
        @completed="queryCompanyExec"
      />
    </div>
  </main>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import IndustryList from "@/utils/Industry";
import HmoProviders from "@/utils/HmoProviders";
import AddDirectorComponent from "@/components/Settings/Business/Directors.vue";

import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import lgData from "@/utils/localGovt";
import Countries from "@/utils/countries";

import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { resetObj, processDate } = helperFunctions;
const loading = ref(false);

const allFounders = computed(() =>
  store.getDirectors && store.getDirectors.data ? store.getDirectors.data : []
);
const founders = ref([]);
const createEmpList = () => {
  const list = allFounders.value.map((emp) => {
    founders.value.push({
      label: emp.fullName,
      value: emp._id,
    });
  });
};
createEmpList();
async function queryCompanyExec() {
  loading.value = true;

  try {
    await query({
      endpoint: "ListCompanyExec",
      service: "SETTINGS",
      storeKey: "directors",
      payload: {
        type: "DIRECTOR",
      },
    });
    loading.value = false;
    createEmpList();
  } catch (e) {
    loading.value = false;
  }
}
queryCompanyExec();
const emit = defineEmits(["registered"]);
const successMessage = ref("");
const updateSuccess = ref("");

const route = useRoute();
const employer = ref(true);
const industryData = IndustryList.map((el) => el.name);
const uploadFile = ref(null);
const localGovtData = ref([]);
const selectedState = computed(() => args.value.state);
const agreement = ref(true);
const useTaxOffice = ref(true);
const isExpatriate = ref(false);
const staffList = ref(false);
const staffSalary = ref(false);
const hasTaxPayerId = ref(false);
const addDirector = ref(false);
const args = ref({
  directors: [],
  tin: "",
});
const allEmployees = computed(() => getAllEmployees.value.data);

// const countryData = computed(() => {
//   let countryArray = [];
//   countryArray = Countries.map((country) => {
//     return country.name;
//   });
//   return countryArray;
// });
// watch(
//   selectedState,
//   (value, newValue) => {
//     console.log(value, "New Value");
//     const lgs = lgData.find(
//       (lg) => lg.state.toLowerCase() === value.toLowerCase()
//     );
//     localGovtData.value = lgs.lgas.map((lg) => {
//       return {
//         label: lg,
//         value: lg,
//       };
//     });
//   },
//   { deep: true }
// );
// const statesData = computed(() => {
//   let stateArray = [];
//   stateArray = NigerianStates.map((state) => {
//     return {
//       label: state,
//       value: state,
//     };
//   });
//   console.log(stateArray);
//   return stateArray;
// });

const today = ref("");
today.value = processDate();
function closeFN() {
  emit("registered");
}

async function registerNsitf() {
  loading.value = true;

  // args.proofOfAddress = await uploadFileToServer(uploadProofOfAddress.value);

  try {
    let res = await mutate({
      endpoint: "RegisterNsitfCompliance",
      data: { input: args.value },
      service: "PAYROLL",
    });
    if (res.success) {
      console.log(res);
      successMessage.value = res.message;
      // removeFiles.value = true;
      // uploadFile.value = null;
      // uploadProofOfAddress.value = null;
      loading.value = false;
      // await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    loading.value = false;
  }
}

function updateLg(event) {
  console.log(event);
  args.value.state = event;
}
</script>

<style>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
</style>
