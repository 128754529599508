<template>
  <div
    v-if="showOptions || backBool"
    class="p-2 w-full text-center flex flex-col gap-y-2"
  >
    <h1 class="font-medium uppercase font-bold">NHF Registration</h1>
    <span class="text-center text-bodyDark text-xs"
      >Kindly select your registration option</span
    >

    <div
      class="cursor-pointer border border-outlineGray p-3 w-11/12 rounded-xl flex flex-col gap-y-3 mt-5 self-center"
    >
      <div
        v-for="type in ['company', 'employee']"
        :key="type"
        @click="handleType(type)"
        class="bg-highlight border border-outlineGray flex justify-between rounded-lg p-2"
      >
        <span class="capitalize text-sm font-bold">
          {{ type }} Registration</span
        >

        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.875 16.25L13.125 10L6.875 3.75"
            stroke="#0F172A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
  <main class="pt-8" v-else>
    <!-- <div class="flex flex-col gap-4 items-center mt-6">
      <h2 class="font-bold text-sm text-dark-800">Please Select NHF Type</h2>
    </div> -->

    <!-- <div
      class="flex items-start md:w-11/12 md:mx-auto w-full border border-outlineGray rounded-xl tracking-wide justify-start mt-4 mb-2 gap-x-2 text-sm font-medium cursor-pointer bg-white p-1"
    >
      <div
        @click="employer = true"
        class="w-full cursor-pointer flex items-center md:justify-center gap-2 px-2 md:px-4 py-2 relative rounded rounded-lg"
        :class="employer ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
      >
        <span class="text-xs md:text-sm">Employer registration</span>
      </div>

      <div
        @click="employer = false"
        class="w-full cursor-pointer flex items-center md:justify-center gap-2 px-2 md:px-4 py-2 relative rounded rounded-lg"
        :class="!employer ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
      >
        <span class="text-xs md:text-sm">Employee registration</span>
      </div>
    </div> -->
    <h2 class="capitalize text-center font-bold text-bodyDark text-xl">
      {{ regType }} registration
    </h2>
    <form
      @submit.prevent="registerNhf"
      autocomplete="on"
      class="flex flex-col text-left mt-5"
    >
      <!-- <h2 class="font-bold text-center mb-5 text-sm md:text-xs text-dark-800">
        Download our NHF{{ employer ? "1" : "2" }} form below and populate it.
      </h2> -->
      <div class="px-3 md:px-10 lg:px-6 xl:px-8">
        <div class="flex flex-col items-center gap-2 mb-7">
          <span class="text-dark-800 text-base font-medium mb-1"
            >Download our NHF {{ employer ? "1" : "2" }} form and populate
            it.</span
          >

          <div
            @click="download(employer ? 'employer' : 'employee')"
            class="w-56 text-center mt-3 shadow-sm rounded-full bg-newLimeGreen border border-outlineGray py-2 px-4 cursor-pointer"
          >
            <!-- <span class="text-secondary text-sm text-center font-medium">
          <i class="fas fa-download mr-3"></i>Download Template</span
        > -->

            <span
              class="w-full flex items-center gap-3 justify-center text-primary text-base text-center font-bold"
            >
              <span>Download NHF{{ employer ? "1" : "2" }} Form</span>
              <img src="@/assets/icons/download-icon2.svg" alt="" />
            </span>
          </div>
        </div>
        <hr class="mb-5 border-0.5 border-dividerColor" />

        <div
          class="py-4 px-4 border border-outlineGray rounded-2xl bg-newGrey flex items-center justify-between"
        >
          <div class="flex items-center gap-2">
            <div
              class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0"
            >
              <img src="@/assets/icons/pdf-icon.svg" alt="" />
            </div>
            <div class="flex flex-col leading-4">
              <div
                v-if="uploadFile && uploadFile.name"
                class="text-center w-9/12 font-bold text-sm flex-col leading-4"
              >
                {{ uploadFile.name }}
              </div>

              <span v-else class="mb-2 text-sm font-bold"
                >Upload the populated form here</span
              >
              <!-- <div
              v-else-if="file.uploadFile && !file.uploadFile.name"
              class="text-center font-bold text-sm flex-col leading-4"
            >
              {{ getLink(file.uploadFile) }}
            </div> -->
            </div>
          </div>

          <!-- <GetStatus v-if="status" :status="'Verified'" :showBorder="false" /> -->
          <span class="bg-white rounded-full">
            <UploadComponent
              :label="'Upload'"
              :showBorder="false"
              :loop="true"
              @fileUrl="uploadFile = $event"
            />
          </span>
        </div>
        <hr class="my-5 border-0.5 border-dividerColor" />

        <!-- <div class="justify-center mb-8">
          <UploadComponent
            @fileUrl="uploadFile = $event"
            :info="'Upload populated form into the system'"
            :hideType="true"
          />
        </div> -->
      </div>

      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Submit
        </button>
      </div>
    </form>

    <easiModal v-if="registerForCode" @close="registerForCode = false">
      <template v-slot:header
        ><h1 class="text-dark-800 font-medium">
          Register for Employer Code
        </h1></template
      >
      <form autocomplete="on" class="flex flex-col text-left">
        <div class="px-3 md:px-12 lg:px-8 xl:px-10">
          <div class="w-full flex flex-col mb-5">
            <div class="justify-center mb-8">
              <UploadComponent
                @fileUrl="uploadFile = $event"
                :pill="true"
                :tiny="true"
                :label="'Choose File'"
                :info="'Upload CAC Document (Jpeg and Pdf Only) (10mb max)'"
              />
            </div>

            <div class="justify-center mb-8">
              <UploadComponent
                @fileUrl="uploadFile = $event"
                :pill="true"
                :tiny="true"
                :label="'Choose File'"
                :info="'Upload MEMART (Jpeg and Pdf Only) (10mb max)'"
              />
            </div>

            <label for="tin" class="text-dark-800 text-sm font-medium"
              >TIN
            </label>
            <easiTextInput
              @input="updateArgs"
              placeholder="TIN"
              type="text"
              name="tin"
              class="mt-1 mb-8"
              required
            ></easiTextInput>
          </div>
        </div>
        <hr class="mb-5 border-0.5 border-dividerColor text-dividerColor" />
        <div class="px-3 md:px-12 lg:px-8 xl:px-10">
          <button
            class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-secondary rounded-lg text-white"
          >
            Submit
          </button>
        </div>
      </form>
    </easiModal>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import axios from "axios";

import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import IndustryList from "@/utils/Industry";
import HmoProviders from "@/utils/HmoProviders";

import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import lgData from "@/utils/localGovt";
import Countries from "@/utils/countries";

import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

const props = defineProps({
  goBack: {
    type: Boolean,
    default: false,
  },
});
const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { resetObj, processDate, uploadFileToServer } = helperFunctions;
const emit = defineEmits(["registered", "back"]);

const route = useRoute();
const employer = ref(true);
const successMessage = ref("");
const updateSuccess = ref("");
const loading = ref(false);
const isChosen = ref(false);
const showOptions = ref(true);

const industryData = IndustryList.map((el) => el.name);
const uploadFile = ref(null);
const localGovtData = ref([]);
const selectedState = computed(() => args.value);
const agreement = ref(true);
const useTaxOffice = ref(true);
const isExpatriate = ref(false);
const hasTaxPayerId = ref(false);
const registerForCode = ref(false);
const btnState = ref("primary");
const regType = ref("");

const args = ref({
  url: "",
});
const watchProp = computed(() => props.goBack);
const backBool = ref(false);

watch(watchProp, () => {
  console.log("back is ", watchProp.value);
  if (watchProp.value) {
    showOptions.value = true;
  }
  backBool.value = watchProp.value;
});
const allEmployees = computed(() => getAllEmployees.value.data);

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
// const countryData = computed(() => {
//   let countryArray = [];
//   countryArray = Countries.map((country) => {
//     return country.name;
//   });
//   return countryArray;
// });
// watch(
//   selectedState,
//   (value, newValue) => {
//     console.log(value, "New Value");
//     const lgs = lgData.find(
//       (lg) => lg.state.toLowerCase() === value.toLowerCase()
//     );
//     localGovtData.value = lgs.lgas.map((lg) => {
//       return {
//         label: lg,
//         value: lg,
//       };
//     });
//   },
//   { deep: true }
// );
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  console.log(stateArray);
  return stateArray;
});
const today = ref("");
today.value = processDate();

function updateLg(event) {
  console.log(event);
  // args.value.state = event;
}

function download(template) {
  if (template) {
    btnState.value = "disabled";
    let host = window.location.protocol + "//" + window.location.host;
    axios({
      url: `${host}/uploads/${
        template === "employer"
          ? "Employer_Registration_Form.NHF.pdf"
          : "Staff_Registration_Form.NHF.pdf"
      }`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `${
          template === "employer"
            ? "Employer_Registration_Form.NHF.pdf"
            : "Staff_Registration_Form.NHF.pdf"
        }`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      btnState.value = "outline";
    });
  }
}
function closeFN() {
  emit("registered");
}
async function registerNhf() {
  loading.value = true;

  let payload = {};

  if (uploadFile.value) {
    args.value.url = await uploadFileToServer(uploadFile.value);
  }
  // args.proofOfAddress = await uploadFileToServer(uploadProofOfAddress.value);
  const keys = Object.keys(args.value);
  if (args.value && args.value.url && args.value.url.length) {
    for (let k of keys) {
      if (args.value[k].length) {
        payload[k] = args.value[k];
      }
    }
  } else {
    toast.error("Upload Failed");
    return false;
  }

  try {
    let res = await mutate({
      endpoint: "RegisterNhfCompliance",
      data: { input: payload },
      service: "PAYROLL",
    });
    if (res.success) {
      console.log(res);
      successMessage.value = res.message;
      // removeFiles.value = true;
      // uploadFile.value = null;
      // uploadProofOfAddress.value = null;
      loading.value = false;
      // await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    loading.value = false;
  }
}

function handleType(type) {
  showOptions.value = false;
  type === "company" ? (employer.value = true) : (employer.value = false);

  isChosen.value = true;
  regType.value = type;
  backBool.value = false;
  emit("back", backBool.value);
}
</script>

<style>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
</style>
