<template>
  <div class="relative p-fluid" style="height: 100vh">
    <div v-if="data.length">
      <div v-if="!empty" class="md:flex hidden justify-between mx-4 mb-2">
        <span class="w-1/2"></span>
        <span
          >{{ currentPage == 0 ? currentPage + 1 : pageSize + currentPage }} -
          {{
            currentPage == 0
              ? pageSize > data.length
                ? data.length
                : pageSize
              : pageSize + visibleData.length
          }}
          of {{ data.length }}</span
        >
      </div>
      <div
        class="mx-4 h-full hidden z-20 md:block"
        :class="editInProgress ? 'z-20 ' : ''"
        style="height: 100vh"
      >
        <DataTable
          :value="visibleData"
          v-model:selection="selectedEmployees"
          dataKey="compliance"
          :resizableColumns="true"
          columnResizeMode="expand"
          :showGridlines="false"
          :paginator="false"
          :rows="20"
          :rowClass="rowClass"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
          :rowsPerPageOptions="[10, 20, 50]"
          :scrollable="true"
          scrollHeight="1000px"
          :loading="!data.length"
          scrollDirection="both"
          editMode="cell"
          @cell-edit-complete="onCellEditComplete"
          class="editable-cells-table"
          responsiveLayout="scroll"
          @rowSelect="onRowSelect"
          @rowUnselect="onRowUnselect"
        >
          <Column
            :frozen="true"
            selectionMode="multiple"
            :headerStyle="headStyle"
            bodyStyle="width: 2em;border-left: 1px solid #E7E8E7;padding-right: 30px;background: #F2F1F1;border: 1px solid #B5B6B5;"
            style="width: 10px !important"
          ></Column>

          <Column
            class="cursor-pointer text-center font-medium text-dark-800 capitalize"
            v-for="col of header"
            :field="col.field"
            :header="label(col.header)"
            :key="col.field"
            :frozen="freeze(col)"
            style="
              width: 220px;
              border-right: 1px solid #b5bde1;
              font-size: 14px;
              color: #231f1f;
            "
            :style="[
              highlightField(col.field),
              hiddenFields.includes(col.field) ? 'display: none' : '',
            ]"
            :headerStyle="[
              col.header === 'totalAmount' ? lastHeaderStyle : style,
              col.header === active ? activeHeader : style,
            ]"
          >
            <template
              v-if="useSelect.includes(col.field)"
              #editor="{ data, field }"
            >
              <Dropdown
                v-model="data[field]"
                :options="returnOptions(field)"
                optionLabel="label"
                optionValue="value"
                placeholder="Select"
                @change="updateMultipleCells($event, data, field)"
              >
                <template #option="slotProps">
                  <span>{{ slotProps.option.label }}</span>
                </template>
              </Dropdown>
            </template>

            <template
              v-else-if="
                !useSelect.includes(col.field) &&
                readonly === false &&
                col.field !== 'deductions'
              "
              #editor="{ data, field }"
            >
              <InputText
                @focus="editInProgress = true"
                v-model="data[field]"
                :ref="field"
                @keyup="updateMultipleCells($event, data, field)"
                autofocus
              />
            </template>

            <template v-if="col.field === `${useForOption}`" #body="slotProps">
              <!-- <div class="flex items-center gap-2"> -->
              <!-- <div
                  v-if="employee.profile.picture"
                  class="border w-9 h-9 rounded-full overflow-hidden"
                >
                  <img
                    class="-full"
                    src="../../assets/img/avatar-1.png"
               
                  />
                </div> -->
              <!-- <div
                :style="`background-color:${getAvatar(slotProps.data).color}`"
                class="p-2 h-9 w-9 text-sm text-white font-medium rounded-full mr-2"
              >
                {{ getAvatar(slotProps.data).initialLetters }}
              </div>
            </div> -->
              <div
                @click="onRowClick(slotProps.data)"
                class="w-full text-left capitalize font-medium text-dark-800"
                :class="[
                  error(slotProps.data, col.field),
                  bankError(slotProps.data, col.field),
                ]"
              >
                {{ slotProps.data[col.field] }}
              </div>
            </template>
            <template
              v-else-if="
                col.field === `compliance` || col.field === `typeOfRegistration`
              "
              #body="slotProps"
            >
              <div
                @click="onRowClick(slotProps.data)"
                class="w-full text-left capitalize text-sm font-medium text-dark-800"
                :class="[
                  error(slotProps.data, col.field),
                  bankError(slotProps.data, col.field),
                ]"
              >
                {{
                  slotProps.data[col.field]
                    .replace(/([A-Z])/g, " $1")
                    .toUpperCase()
                }}
              </div>
            </template>
            <template
              v-else-if="col.field === `dateOfRegistration`"
              #body="slotProps"
            >
              <div
                @click="onRowClick(slotProps.data)"
                class="w-full text-left capitalize text-sm font-medium text-dark-800"
                :class="[
                  error(slotProps.data, col.field),
                  bankError(slotProps.data, col.field),
                ]"
              >
                {{ formatDateString(slotProps.data[col.field]) }}
              </div>
            </template>
            <template v-else-if="col.field === `status`" #body="slotProps">
              <div
                @click="onRowClick(slotProps.data)"
                class="w-full text-left uppercase text-sm font-bold"
                :class="[
                  {
                    'text-error':
                      slotProps.data[col.field].toLowerCase() === 'pending',
                  },
                  error(slotProps.data, col.field),
                  bankError(slotProps.data, col.field),
                ]"
              >
                {{ slotProps.data[col.field] }}
              </div>
            </template>
            <template
              v-else-if="amountValues.includes(col.field)"
              #body="slotProps"
            >
              <div
                @click="onRowClick(slotProps.data)"
                class="w-full"
                :class="[
                  error(slotProps.data, col.field),
                  bankError(slotProps.data, col.field),
                ]"
              >
                {{ formatAmount(slotProps.data[col.field]) }}
              </div>
            </template>
            <template v-else-if="col.field === 'percentage'" #body="slotProps">
              <div class="flex items-center gap-2"></div>
              <div
                @click="onRowClick(slotProps.data)"
                class="w-full"
                :class="[
                  error(slotProps.data, col.field),
                  bankError(slotProps.data, col.field),
                ]"
              >
                {{ slotProps.data[col.field] || "N/A" }}
              </div>
            </template>
            <!--For Benefits Array -->
            <template v-else-if="col.field === 'duration'" #body="slotProps">
              <div
                @click="onRowClick(slotProps.data)"
                class="w-full"
                :class="[
                  error(slotProps.data, col.field),
                  bankError(slotProps.data, col.field),
                ]"
              >
                {{ getDuration(slotProps.data[col.field]) || "N/A" }}
              </div>
            </template>

            <template v-else-if="col.field !== 'totalAmount'" #body="slotProps">
              <div
                @click="onRowClick(slotProps.data)"
                class="w-full"
                :class="[
                  error(slotProps.data, col.field),
                  bankError(slotProps.data, col.field),
                  empty ? 'invisible' : '',
                ]"
              >
                {{ slotProps.data[col.field] || "N/A" }}
              </div>
            </template>
            <template v-else-if="col.field === 'totalAmount'" #body="slotProps">
              <div
                :class="empty ? 'invisible' : ''"
                class="static flex justify-between w-full capitalize"
                ref="parent"
                @scroll="checkDropdownPosition"
              >
                <div :class="[error(slotProps.data, col.field)]">
                  {{ formatAmount(slotProps.data[col.field]) }}
                </div>
                <div
                  @click="showAll(slotProps.data.id)"
                  class="absolute cursor-pointer right-4 z-5 w-4"
                >
                  <img src="@/assets/icons/more-icon.svg" alt="" />
                </div>
                <div
                  v-show="showOptions === slotProps.data.id"
                  class="w-52 absolute bottom-20 group-hover:text-dark-800 flex right-10 flex-col z-50 bg-white rounded-3xl shadow-lg"
                  :style="{ top: dropdownTop, bottom: dropdownBottom }"
                  style="z-index: 1"
                >
                  <div
                    class="flex flex-col z-50 text-left items-start text-dark-800 text-sm cursor-pointer pt-2"
                  >
                    <span
                      class="hover:bg-mint hover:text-primary py-2 px-4 w-full"
                      @click="onRowClick(slotProps.data, 'view')"
                    >
                      View
                    </span>
                    <span
                      class="hover:bg-mint hover:text-primary py-2 px-4 w-full"
                      @click="onRowClick(slotProps.data, 'edit')"
                    >
                      Edit
                    </span>

                    <span
                      @click="openDeleteModal(slotProps.data)"
                      class="hover:bg-mint hover:text-primary py-2 px-4 w-full"
                      >Delete</span
                    >
                  </div>
                </div>
              </div>
            </template>
            <template
              v-else-if="namesForAllowances.includes(col.field)"
              #body="slotProps"
            >
              <div class="flex items-center gap-2"></div>
              <div
                :class="[
                  error(slotProps.data, col.field),
                  bankError(slotProps.data, col.field),
                ]"
              >
                {{
                  slotProps.data["usePercentage"] === true
                    ? formatAmount(
                        calculatePercentage(
                          slotProps.data["grossPay"],
                          slotProps.data[col.field]
                        )
                      )
                    : formatAmount(slotProps.data[col.field])
                }}
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="flex md:hidden w-full pl-3 pr-2" v-if="data.length">
        <div class="w-full">
          <div class="w-full flex items-center justify-between mt-4 mb-2">
            <div class="flex invisible items-center gap-2">
              <input type="checkbox" class="h-4 w-4" />

              <span class="text-dark-600">Select All</span>
            </div>

            <div>
              <span
                >{{
                  currentPage == 0 ? currentPage + 1 : pageSize + currentPage
                }}
                -
                {{
                  currentPage == 0
                    ? pageSize > data.length
                      ? data.length
                      : pageSize
                    : pageSize + visibleData.length
                }}
                of {{ data.length }}</span
              >
            </div>
          </div>
          <div v-if="loading" class="w-full">
            <easiLoader />
          </div>
          <div v-for="dat in data" :key="dat.id" class="w-full mb-8">
            <table
              v-for="k in Object.keys(dat)"
              :key="k"
              class="table-auto w-full overflow-y-auto"
            >
              <tbody class="font-medium text-sm">
                <tr v-if="!hiddenFields.includes(k)">
                  <td
                    class="w-4/12 border text-left p-2 bg-highlight border-outlineGray"
                  >
                    <div
                      class="flex items-center gap-2 uppercase text-primary font-bold text-sm"
                    >
                      {{
                        k === "totalAmount"
                          ? "Amount"
                          : k.replace(/([A-Z])/g, " $1")
                      }}
                    </div>
                  </td>
                  <td
                    :class="[
                      highlight.includes(k) ? 'bg-background-200' : 'bg-white',
                    ]"
                    class="w-8/12 capitalize border border-outlineGray text-left p-2 px-3 group-hover:bg-indigo-100 group-hover:border-outlineGray"
                  >
                    <span v-if="k === 'totalAmount'">{{
                      formatAmount(dat[k])
                    }}</span>
                    <span
                      class="uppercase"
                      v-else-if="
                        k === 'compliance' || k === 'typeOfRegistration'
                      "
                      >{{ dat[k].replace(/([A-Z])/g, " $1") }}</span
                    >
                    <span
                      class="font-medium uppercase"
                      :class="
                        dat[k].toLowerCase() === 'pending'
                          ? 'text-error'
                          : undefined
                      "
                      v-else-if="k === 'status'"
                      >{{ dat[k] }}</span
                    >
                    <span
                      class="uppercase"
                      v-else-if="k === 'dateOfRegistration'"
                      >{{ formatDateString(dat[k]) }}</span
                    >
                    <span v-else>{{ dat[k] }}</span>

                    <!-- {{ k === "totalAmount" ? formatAmount(dat[k]) : dat[k] }} -->

                    <img
                      v-show="k == 'compliance'"
                      @click="showAll(dat['id'])"
                      class="absolute -mt-5 right-4 z-5"
                      src="@/assets/icons/more-icon.svg"
                      alt=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              @click="
                showAllData == dat.id
                  ? (showAllData = '')
                  : (showAllData = dat.id)
              "
              class="w-full bg-dark-100 text-dark-900 border border-outlineGray flex items-center justify-between text-xs py-0 px-3"
            >
              <div
                v-show="showOptions === dat['id']"
                class="w-52 absolute -mt-52 group-hover:text-dark-800 flex right-5 mt-3 flex-col z-50 bg-white rounded-xl shadow-lg"
              >
                <div
                  class="flex flex-col z-50 text-left items-start text-dark-800 text-sm cursor-pointer pt-2"
                >
                  <span
                    class="hover:bg-primary-hover hover:text-white py-2 px-4 w-full"
                    @click="onRowClick(dat, 'view')"
                  >
                    View
                  </span>
                  <span
                    class="hover:bg-primary-hover hover:text-white py-2 px-4 w-full"
                    @click="onRowClick(dat, 'edit')"
                  >
                    Edit
                  </span>

                  <span
                    @click="openDeleteModal(dat)"
                    class="hover:bg-primary-hover hover:text-white py-2 px-4 w-full"
                    >Delete</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        @click="showOptions = ''"
        :class="!showOptions.length ? 'pointer-events-none' : ''"
        class="bg-transparent fixed inset-0"
      ></div> -->
    </div>

    <easiPagin
      v-if="data.length"
      :data="data"
      @page:update="updatePage"
      :currentPage="currentPage"
      :visibleData="visibleData"
      :pageSize="pageSize"
      class="w-full md:mt-4"
    />
    <!-- /////////// Also unhide the top page and length indicator ////// -->

    <!-- /////////// Search Not found ////// -->
    <div
      v-if="isSearch && !data.length"
      class="container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
    >
      <div class="flex flex-col text-left col-span-2 md:col-span-1">
        <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">
          Oops!
        </h1>
        <div class="text-sm md:text-lg">Sorry we could not find a result</div>
        <p class="text-xs md:text-sm mt-2">
          Check your spelling or try a different name. Make sure you search by
          <span class="text-secondary font-medium">Name</span>,
        </p>
      </div>
      <div class="col-span-2 md:col-span-1">
        <img src="@/assets/icons/search_null.svg" alt="" />
      </div>
    </div>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span class="capitalize"
          >{{ deleteId.replace(/([A-Z])/g, " $1") }} deleted successfully</span
        >
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span class="uppercase"
          >Are you sure you want to delete
          {{ deleteId.replace(/([A-Z])/g, " $1") }}?</span
        >
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading"
              @click="deleteDeduction"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { helperFunctions } from "@/composable/helperFunctions";
import { computed, ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { mapWritableState, storeToRefs } from "pinia";
import { mapActions, mapState } from "pinia";

export default {
  setup(props) {
    const { formatAmount, calculatePercentage, formatDateString } =
      helperFunctions;
    const editInProgress = ref(false);
    const store = useDataStore();
    const { query } = store;
    const { allowance, listEmployees } = storeToRefs(store);
    const showAllData = ref("");
    const toggleFields = ref(["employee", "dateCreated"]);
    const hiddenFields = ref([
      "id",
      "endDate",
      "employeeIds",
      "staffLevelId",
      "benefitEnum",
    ]);
    const loading = ref(false);
    const checkState = (name) => {
      console.log(name);
      if (showAllData.value === name) {
        return "hidden";
      } else {
        return "";
      }
    };

    //Pagination
    const currentPage = ref(0);
    const pageSize = ref(15);
    let visibleData = ref([]);

    const propsData = computed(() => props.data);

    const updatePage = (pageNumber) => {
      currentPage.value = pageNumber;
      updateVisibleData();
    };
    const updateVisibleData = () => {
      let datas = propsData.value && propsData.value;
      visibleData.value = datas.slice(
        currentPage.value * pageSize.value,
        currentPage.value * pageSize.value + pageSize.value
      );

      // if we have 0 visible todos, go back a page
      if (visibleData.value.length == 0 && currentPage.value > 0) {
        updatePage(currentPage.value - 1);
      }
    };
    // updateVisibleData();
    return {
      formatAmount,
      calculatePercentage,
      formatDateString,
      editInProgress,
      store,
      loading,
      toggleFields,
      showAllData,
      checkState,
      hiddenFields,
      visibleData,
      updatePage,
      currentPage,
      pageSize,
      updateVisibleData,
      propsData,
    };
  },
  emits: ["selected", "deleted"],
  props: {
    isSearch: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Boolean,
      default: false,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    useForOption: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: [],
    },
    namesForAllowances: {
      type: Array,
      default: [],
    },
    amountValues: {
      type: Array,
      default: [],
    },
    header: {
      type: Array,
      default: [],
    },
    mapData: {
      type: Array,
      default: [],
    },
    frozenFields: {
      type: Array,
      default: [],
    },
    highlight: {
      type: Array,
      default: [],
    },
    useSelect: {
      type: Array,
      default: [],
    },
    mandatory: {
      type: Array,
      default: [],
    },
    routable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showOptions: "",
      selectedEmployees: null,
      editingRows: [],
      columns: null,
      holdData: [],
      active: "",
      updateSuccess: false,
      deleteWarning: false,
      deleteId: "",
      deleteArgs: {
        staffLevelIds: [],
        deduction: [],
      },
      dropdownStyle: "top-0",
      dropdownStyle2: "top-16",
      dropdownTop: "0%",
      dropdownBottom: "auto",
    };
  },

  computed: {
    style() {
      return "padding-top: 0.75rem; padding-left:auto; padding-right: auto; padding-bottom: 0.75rem; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: capitalize; border-width: 1px; border-top-width: 1px; background: rgba(240, 247, 235, 1);margin-bottom: 10px;color: #515251; border-left:0px; border-right: 1px solid #E7E8E7; border-top: 1px solid #E7E8E7; border-bottom: 1px solid #E7E8E7; font-size: 12px; box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);";
    },
    activeHeader() {
      return " padding-top: 0.75rem; padding-bottom: 0.75rem; background-color: #E7E8E7; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: capitalize; border-width: 2px; border-right:0px; border-top-width: 0; margin-bottom: 10px;color: #ffffff; font-size: 14px;";
    },
    headStyle() {
      return "width: 3em; background: background: #F2F1F1; padding-top: 0.75rem; padding-bottom: 0.75rem;  border-top-width: 0;margin-bottom: 10px;border-top-left-radius: 1rem;border-bottom-left-radius: 0rem; border-left: 1px solid #E7E8E7; border-right:0px; border-top: 1px solid #E7E8E7; border-bottom: 1px solid #E7E8E7; font-size: 12px; text-align: left; padding-right: 25px";
    },
    lastHeaderStyle() {
      return "padding-top: 0.75rem; padding-bottom: 0.75rem; line-height: 16px; font-weight: 700; letter-spacing: 0.025em; text-align: center;  text-transform: capitalize; border-width: 2px; border-top-width: 0; background: rgba(240, 247, 235, 1);margin-bottom: 10px;color: #515251; font-size: 14px; border-top-right-radius: 1rem;border-bottom-right-radius: 0rem; border-right: 1px solid #E7E8E7; border-left:0px; border-top: 1px solid #E7E8E7; border-bottom: 1px solid #E7E8E7; ";
    },
    label() {
      return (str) =>
        str
          .split(".")
          .pop()
          .replace(/([A-Z])/g, " $1")
          .toUpperCase();
    },
    freeze() {
      return (field) => {
        if (this.frozenFields.includes(field.field)) {
          return true;
        }
        return false;
      };
    },
    highlightField() {
      return (field) => {
        if (this.highlight.includes(field)) {
          return "background: rgba(240, 247, 235, 1);  ";
        }
        return "background: #F9FAFC;";
      };
    },
    // mandatoryField() {
    //   return (field) => {
    //     if (this.mandatory.includes(field)) {
    //       return "border: 1px solid #FFF0EC";
    //     }
    //   };
    // },
    data() {
      return this.data;
    },
  },
  watch: {
    data() {
      this.updateVisibleData();
    },
  },

  methods: {
    ...mapActions(useDataStore, ["query", "mutate"]),
    checkDropdownPosition() {
      const parentElement = this.$refs.parent;
      const parentHeight = parentElement.clientHeight;
      const parentScrollTop = parentElement.scrollTop;

      const dropdownElement = this.$el;
      const dropdownHeight = dropdownElement.clientHeight;

      const dropdownTopPercentage = (parentScrollTop / parentHeight) * 100;
      const dropdownBottomPercentage =
        ((parentHeight - (parentScrollTop + dropdownHeight)) / parentHeight) *
        100;

      this.dropdownTop = dropdownTopPercentage + "%";
      this.dropdownBottom = dropdownBottomPercentage + "%";
    },

    async updateStatus(data, type, action) {
      this.loading = true;
      let input = {
        type: type === "bonusName" ? "Bonus" : "Deduction",
        name: data[type],
        employeeIds: data.employeeIds,
        amount: data.amount ? data.amount : 0,
        percentage: data.percentage ? data.percentage : 0,
        duration: data.duration ? data.duration : "",
        endDate: data.endDate,
        status: action,
      };
      console.log(data, type, action);
      try {
        let res = await this.mutate({
          endpoint: "UpdateBonusOrDeduction",
          service: "PAYROLL",
          data: {
            updateBonusOrDeductionId: data.id,
            input,
          },
        });
        this.loading = false;
        if (res._id) {
          this.updateSuccess = true;
          // await queryBonusOrDed();
          setTimeout(() => {
            window.location.reload();
            // router.go(-1);
          }, 1000);
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    openDeleteModal(data) {
      console.log(data, "DELETE");

      this.deleteId = data.id;
      this.deleteArgs.staffLevelIds = data.staffLevelId;
      this.deleteArgs.deduction = data.benefitEnum;
      this.deleteWarning = true;
    },

    async deleteDeduction() {
      this.loading = true;
      try {
        let res = await this.mutate({
          endpoint: "DeleteDeductionsMultiple",
          service: "PAYROLL",
          data: {
            input: this.deleteArgs,
          },
        });
        this.loading = false;
        if (res.success) {
          this.deleteWarning = false;
          this.updateSuccess = true;
          this.$emit("deleted");
          // queryBonusOrDed();
          setTimeout(() => {
            this.updateSuccess = false;
          }, 1000);
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    showAll(arg) {
      console.log(arg);
      if (this.showOptions === arg) {
        this.showOptions = "";
      } else {
        this.showOptions = arg;
      }
    },
    rowClass() {
      return "row-accessories";
    },
    getStatusColor(status) {
      switch (status) {
        case "COMPLETED":
          return "text-successStatus font-medium";

        case "ACTIVE":
          return "text-info font-medium";

        case "PAUSED":
          return "text-secondary font-medium";

        default:
          return "NA";
      }
    },
    getStatus(status) {
      switch (status) {
        case "COMPLETED":
          return "Completed";

        case "ACTIVE":
          return "Active";

        case "PAUSED":
          return "Paused";

        default:
          return "NA";
      }
    },
    getDuration(status) {
      switch (status) {
        case "WEEKLY":
          return "Weekly";

        case "MONTHLY":
          return "Monthly";

        case "ANNUALLY":
          return "Annually";

        case "ONE_TIME":
          return "One-Time";

        default:
          return "NA";
      }
    },
    returnOptions(fieldType) {
      if (fieldType === "employmentType") {
        return this.mapData[0];
      } else if (fieldType === "branch") {
        return this.mapData[1];
      }
    },
    updateMultipleCells(event, obj, objkey) {
      console.log("1", event, "2", obj, "3", objkey);
      this.active = objkey;
      for (let i = 0; i < this.holdData.length; i++) {
        if (event.target) {
          this.holdData[i][objkey] = event.target.value;
          obj[objkey] = event.target.value;
        } else {
          this.holdData[i][objkey] = event.value;
          obj[objkey] = event.value;
        }
      }
    },
    initials(name) {
      const arr = name.split(" ");
      const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
        .charAt(0)
        .toUpperCase()}`;
      return str;
    },
    getColor(index) {
      let n = (index * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    },

    getAvatar(employee) {
      let name = `${employee.firstName} ${employee.lastName}`;
      let initialLetters = this.initials(name);

      let color;
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
      const initialOne = String(
        employee && employee.firstName && employee.firstName.charAt(0)
      );
      const initialTwo = String(
        employee && employee.lastName && employee.lastName.charAt(0)
      );
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) +
        Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = this.getColor(num);
      // Get color

      return {
        color,
        initialLetters,
      };
    },
    onRowSelect(event) {
      this.holdData.push(event.data);
      this.$emit("selected", this.holdData);
    },
    onRowUnselect(event) {
      this.holdData = this.holdData.filter((emp) => {
        return emp.employeeId !== event.data.employeeId;
      });
      this.$emit("selected", this.holdData);
    },
    onRowClick(
      event,
      type = this.$route.path.includes("view") ? "view" : "edit"
    ) {
      if (this.routable) {
        const itemId = event["id"];

        this.store.$patch({
          complianceItem: type,
        });
        if (type === "edit") {
          this.$router.push({
            name: "EditSingleCompliance",
            params: { id: itemId },
          });
        } else {
          this.$router.push({
            name: "ViewSingleCompliance",
            params: { id: itemId },
          });
        }
      } else {
        return undefined;
      }
    },
    bankError(data, field) {
      if (data.bankError) {
        console.log(data.bankError, "RE");

        const bankError = data.bankError;
        if (
          field === "bankName" ||
          field === "bankCode" ||
          field === "accountNumber"
        ) {
          return [
            {
              "border border-error-table w-full h-full text-error-table p-2":
                bankError.length > 0,
            },
          ];
        }
      }
    },
    error(data, field) {
      if (data.reason) {
        console.log(data.reason, "RE");

        const reason = data.reason;

        return [
          {
            "border border-error-table w-full h-full text-error-table p-2":
              reason.includes(field),
          },
        ];
      }
    },
    madatoryClass(data, field) {
      console.log("DA", data);
      return [
        {
          "border border-secondary w-full h-full text-secondary":
            data[field].length === 0,
        },
      ];
    },
    onCellEditComplete(event) {
      this.editInProgress = false;
      let { data, newValue, field } = event;
      console.log(newValue, "NEWS");

      switch (field) {
        case "quantity":
        case "price":
          if (this.isPositiveInteger(newValue)) data[field] = newValue;
          else event.preventDefault();
          break;

        default:
          if (newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
    },
    isPositiveInteger(val) {
      let str = String(val);
      str = str.trim();
      if (!str) {
        return false;
      }
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      this.products2[index] = newData;
    },
    getStatusLabel(status) {
      switch (status) {
        case "INSTOCK":
          return "In Stock";

        case "LOWSTOCK":
          return "Low Stock";

        case "OUTOFSTOCK":
          return "Out of Stock";

        default:
          return "NA";
      }
    },
  },
  // mounted() {
  //   this.updateVisibleData();
  // },
  created() {
    this.updateVisibleData();
  },
};
</script>

<style scoped>
::v-deep(.row-accessories) {
  text-align: center !important;
  position: relative;
}

::v-deep(.p-datatable-wrapper) {
  height: 100vh;
  position: relative;
}
</style>
