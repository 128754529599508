<template>
  <main>
    <div class="relative w-full">
      <div
        @click="toggleFilter = true"
        class="border border-dark-300 rounded-lg md:rounded-2xl md:p-5 p-2 md:px-4 md:py-2 cursor-pointer flex items-center gap-2 bg-white w-auto w-full"
        :class="bonus ? 'rounded-l-none' : ''"
      >
        <img
          class="hidden md:block w-6 ml-auto md:w-4"
          src="@/assets/icons/compliance-filter.svg"
          alt=""
        />

        <svg
          class="md:hidden"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 4L13.5 4M7 4C7 4.55228 6.55228 5 6 5C5.44772 5 5 4.55228 5 4M7 4C7 3.44772 6.55229 3 6 3C5.44772 3 5 3.44771 5 4M2.5 4H5M7 12H13.5M7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12M7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12M2.5 12L5 12M11 8L13.5 8M11 8C11 8.55228 10.5523 9 10 9C9.44772 9 9 8.55228 9 8M11 8C11 7.44772 10.5523 7 10 7C9.44772 7 9 7.44771 9 8M2.5 8H9"
            stroke="#8D8E8D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span class="font-medium hidden md:flex text-xs text-left">
          {{ activeFilter || "Filter" }}
        </span>
      </div>
      <div
        v-show="toggleFilter && !transaction"
        class="absolute h-auto w-52 right-0 top-16 shadow-lg bg-white flex flex-col text-sm py-2"
        style="z-index: 2"
      >
        <div
          v-for="option in sortOptions"
          :key="option.name"
          class="flex items-center gap-3 cursor-pointer px-4 py-2 hover:bg-newLimeGreen"
          @click="sortFunc(option)"
        >
          <span>
            <img
              v-if="option.active"
              class="w-4"
              src="../../assets/icons/check3-active.svg"
              alt=""
            />
            <img
              v-else
              class="w-4"
              src="../../assets/icons/check3-null.svg"
              alt=""
            />
          </span>
          <span class="capitalize">{{ option.name }}</span>
        </div>

        <div
          v-for="item in filterOptions"
          :key="item.name"
          @mouseover="item.active = true"
          @mouseout="item.active = false"
          class="relative group flex px-4 py-2 cursor-pointer hover:bg-newLimeGreen z-50 ml-9 md:ml-0"
          style="z-index: 999"
        >
          {{ item.name }}
          <div
            v-show="item.active"
            :class="dropdownStyle"
            class="w-48 group-hover:text-dark-800 absolute -left-48 flex flex-col rounded-b-xl bg-white shadow-lg"
          >
            <div
              v-for="option in item.options"
              :key="option"
              class="flex items-center gap-3 cursor-pointer px-4 py-2 z-50 hover:bg-newLimeGreen"
              style="z-index: 999"
            >
              <form
                v-if="item.value === 'createdAt'"
                @submit.prevent="
                  filterFunc(item.value, option.value, option.name)
                "
                class="flex flex-col gap-2 pt-4"
              >
                <div class="flex flex-col items-start px-0">
                  <label for="startDate">Start Date</label>
                  <input
                    v-model="filterOptions[0].options[0].value.startDate"
                    class="focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-2 px-4 block appearance-none rounded-2xl my-1"
                    type="date"
                    required
                    name=""
                    id=""
                  />
                </div>

                <div class="flex flex-col items-start px-0">
                  <label for="startDate">End Date</label>
                  <input
                    v-model="filterOptions[0].options[0].value.endDate"
                    class="focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-2 px-4 block appearance-none rounded-2xl my-1"
                    type="date"
                    required
                    name=""
                    id=""
                  />
                </div>

                <!-- <div class="flex flex-col items-start px-4">
                <label for="endDate">End Date</label>
                <input
                  class="focus:border-primary focus:ring-primary border-dark-200 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border rounded-sm py-2 px-4 block appearance-none rounded-lg my-1"
                  type="date"
                  name=""
                  id=""
                />
              </div> -->
                <button
                  type="submit"
                  class="text-white text-sm font-medium focus:outline-none bg-primary rounded-xl w-full p-2 mt-2"
                >
                  Submit
                </button>
              </form>
              <div
                @click="filterFunc(item.value, option.value, option.name)"
                class="flex items-center gap-2"
                v-else
              >
                <span>
                  <img
                    v-if="option.active"
                    class="w-4"
                    src="../../assets/icons/check3-active.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="w-4"
                    src="../../assets/icons/check3-null.svg"
                    alt=""
                  />
                </span>
                <span class="uppercase">{{ option.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        @click.self="toggleFilter = false"
        v-show="toggleFilter && transaction"
        :class="toggleFilter && transaction ? 'w-full mod z-20' : undefined"
      >
        <div
          class="absolute h-auto w-100 rounded-3xl left-1/3 top-16 shadow-lg bg-white flex flex-col text-sm px-4 py-4"
          style="z-index: 2"
        >
          <div class="relative w-full mt-3">
            <p class="text-primary-deep text-center text-xl font-bold">
              Filter
            </p>
            <div
              @click="toggleFilter = false"
              class="cursor-pointer absolute top-0 right-0"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="28"
                  height="28"
                  rx="8"
                  fill="#EF4444"
                  fill-opacity="0.1"
                />
                <path
                  d="M9 19L19 9M9 9L19 19"
                  stroke="#EF4444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div class="flex flex-col mt-4 gap-2">
            <span class="text-bodyDark">Sort by</span>
            <div
              class="bg-outlineGray flex items-center rounded-xl py-2 w-full"
              :class="
                filterData && filterData.names && filterData.names.length
                  ? 'gap-3 px-2 py justify-between'
                  : 'justify-center px-2 gap-4'
              "
            >
              <div
                v-for="ded in statutoryDeds"
                :key="ded.key"
                :class="[
                  filterData &&
                  filterData.names &&
                  filterData.names.includes(dedsTable[ded.key])
                    ? 'bg-white rounded-lg p-2'
                    : undefined,
                  filterData && filterData.names && filterData.names.length
                    ? 'gap-2'
                    : 'gap-2',
                ]"
                @click="updateType(ded.key)"
                class="flex cursor-pointer items-center"
              >
                <span
                  :class="
                    filterData &&
                    filterData.names &&
                    filterData.names.includes(dedsTable[ded.key])
                      ? 'text-dark-800'
                      : 'text-dark-200'
                  "
                  class="uppercase text-xs font-bold"
                  >{{ ded.key }}</span
                >
              </div>
            </div>
          </div>

          <hr class="text-outlineGray w-full mt-3 mb-1" />

          <div
            v-for="tim in ['from', 'to']"
            :key="tim"
            class="flex w-full gap-x-4 items-center"
          >
            <span class="w-4/12 text-dark-800 capitalize">{{ tim }}</span>
            <div class="mt-0 w-full justify-end">
              <datePicker
                @update="filterData[tim] = $event"
                :value="filterData[tim]"
                :compliance="true"
                placeholder="DD/MM/YYYY"
              />
            </div>
          </div>
          <hr class="text-outlineGray w-full mt-3 mb-1" />
          <div class="flex w-full gap-3 items-center mt-3">
            <div class="w-full">
              <easiButton
                @click="resetFilter"
                size="medium"
                class="w-full"
                variant="outlined"
                >Reset</easiButton
              >
            </div>
            <div class="w-full">
              <easiButton @click="emitFilter" size="medium" class="w-full"
                >Filter</easiButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="toggleFilter = false"
      :class="!toggleFilter ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import datePicker from "@/components/global/DatePicker.vue";

const store = useDataStore();
const { query } = store;
const { checkLoading, getAllBranches, getAllDepartments } = storeToRefs(store);

export default {
  components: { datePicker },
  props: {
    bonus: {
      type: Boolean,
      default: false,
    },
    transaction: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update", "removeFilter"],

  data() {
    return {
      dropdownStyle: "top-0",
    };
  },
  setup(props, { emit }) {
    const queryBranch = async () => {
      try {
        await query({
          endpoint: "ListBranches",
          service: "SETTINGS",
          storeKey: "listBranches",
        });
      } catch (err) {
        console.log(err);
      }
    };

    const queryDepartment = async () => {
      try {
        await query({
          endpoint: "ListDepartments",
          service: "SETTINGS",
          storeKey: "listDepartments",
        });
      } catch (err) {
        console.log(err);
      }
    };

    queryBranch();
    queryDepartment();

    const allBranches = computed(
      () => getAllBranches.value && getAllBranches.value.data
    );
    const allDepartments = computed(
      () => getAllDepartments.value && getAllDepartments.value.data
    );

    const departmentOptions = computed(() => {
      const arr = [];
      allDepartments.value &&
        allDepartments.value.forEach((el) => {
          if (el) {
            arr.push({
              name: el.name,
              active: false,
              value: el.name,
            });
          }
        });

      return arr;
    });
    const branchOptions = computed(() => {
      const arr = [];
      allBranches.value &&
        allBranches.value.forEach((el) => {
          if (el) {
            arr.push({
              name: el.name,
              active: false,
              value: el.name,
            });
          }
        });

      return arr;
    });

    // const emit = defineEmits(["update"]);

    const activeFilter = ref("");
    const activeFilterArray = ref([]);
    const activeSortArray = ref([]);
    const selected = ref({
      sort: { firstName: "ASC" },
      filter: [],
    });

    const toggleFilter = ref(false);
    const sortOptions = reactive([
      {
        name: "All",
        value: "all",
        active: true,
      },
      {
        name: "A - Z",
        value: "a-z",
        sort: "ASC",
        active: false,
      },
      {
        name: "Z - A",
        value: "z-a",
        sort: "DESC",
        active: false,
      },
    ]);

    const filterOptions = reactive([
      {
        name: "Date Registered",
        active: false,
        value: "createdAt",
        options: [
          {
            name: "Date",
            active: false,
            value: { startDate: "", endDate: "" },
            filter: "comp.date",
          },
        ],
      },
      {
        name: "Compliance Type",
        active: false,
        value: "compType",
        options: [
          {
            name: "PAYE",
            active: false,
            value: "paye",
            filter: "comp.type",
          },
          {
            name: "Pension",
            active: false,
            value: "pension",
            filter: "comp.type",
          },
          {
            name: "HMO",
            active: false,
            value: "hmo",
            filter: "comp.type",
          },
          {
            name: "Life Insurance",
            active: false,
            value: "lifeInsurance",
            filter: "comp.type",
          },
          {
            name: "Life Assurance",
            active: false,
            value: "lifeAssurance",
            filter: "comp.type",
          },
          {
            name: "WHT",
            active: false,
            value: "wht",
            filter: "comp.type",
          },
          {
            name: "NSITF",
            active: false,
            value: "nsitf",
            filter: "comp.type",
          },
          {
            name: "ITF",
            active: false,
            value: "itf",
            filter: "comp.type",
          },
          {
            name: "Gratuity",
            active: false,
            value: "gratuity",
            filter: "comp.type",
          },
          {
            name: "NHF",
            active: false,
            value: "nhf",
            filter: "comp.type",
          },
          {
            name: "Business Expense",
            active: false,
            value: "businessExpense",
            filter: "comp.type",
          },
        ],
      },
    ]);
    const dedsTable = {
      paye: "PAYE",
      pension: "Pension",
      nhf: "NHF",
      nsitf: "NSITF",
      itf: "ITF",
      wht: "WHT",
      lifeAssurance: "Employee_Life_Assurance",
      hmo: "HMO",
      all: "ALL",
    };

    const statutoryDeds = ref([
      {
        name: "ALL",
        style: "bg-primary border border-primary",
        key: "all",
      },
      {
        name: "PAYE",
        style: "bg-primary border border-primary",
        key: "paye",
      },
      {
        name: "NHF",
        style: "bg-primary border border-primary",
        key: "nhf",
      },
      {
        name: "HMO",
        style: "bg-primary border border-primary",
        key: "hmo",
      },
      {
        name: "Pension",
        style: "bg-primary border border-primary",
        key: "pension",
      },

      {
        name: "NSITF",
        style: "bg-primary border border-primary",
        key: "nsitf",
      },
      {
        name: "ITF",
        style: "bg-primary border border-primary",
        key: "itf",
      },
    ]);

    const filterData = ref({
      to: null,
      names: ["ALL"],
      from: null,
    });

    function resetFilter() {
      filterData.value = {
        to: null,
        names: ["ALL"],
        from: null,
      };
    }

    function emitFilter() {
      if (
        filterData.value &&
        filterData.value.names &&
        filterData.value.names.includes("ALL")
      ) {
        filterData.value.names = null;
      }
      toggleFilter.value = false;
      console.log(filterData.value, "fdv");
      emit("update", filterData.value);
    }
    onMounted(() => {
      queryBranch();
      queryDepartment();
    });
    // Functions

    const sortFunc = async (arg) => {
      //reset the sort and filter option
      toggleActiveState("all", false); //remove all active state
      if (arg.name == "All") {
        selected.value.sort = { firstName: "ASC" };
        selected.value.filter = [];
        activeSortArray.value = [];
        activeFilterArray.value = [];

        // unselect Other options
        sortOptions.forEach((el) => {
          el.active = false;
        });

        filterOptions.forEach((el) => {
          el.options.forEach((el) => {
            el.active = false;
          });
        });
      }

      // // unselect All options

      addRemoveOption();

      // Toggle the active state of the option
      function toggleActiveState(payload, state = null) {
        const index = sortOptions.map((el) => el.value).indexOf(payload);
        sortOptions[index].active =
          state !== null ? state : !sortOptions[index].active;
        return sortOptions[index].active;
      }

      //Add/Remove options to/from the sort
      function addRemoveOption() {
        const active = toggleActiveState(arg.value);
        // console.log(active);

        const opposite = {
          "a-z": "z-a",
          "z-a": "a-z",
          hlp: "lhp",
          lhp: "hlp",
        };

        // Check if sort is inside the selected object before adding or removing
        if (typeof selected.value.sort == "undefined") {
          addRemove();
          selected.value.sort = {};
        } else {
          addRemove();
        }

        function addRemove() {
          if (active) {
            // Add sort to selected
            if (arg.value === "a-z" || arg.value === "z-a") {
              selected.value.sort.firstName = arg.sort;
              toggleActiveState(opposite[arg.value], false); //delect the opposite sort option active state
            } else if (arg.value === "hlp" || arg.value === "lhp") {
              selected.value.sort.pay = arg.sort;
              toggleActiveState(opposite[arg.value], false); //delect the opposite sort option active state
            }
          } else {
            // Remove sort to selected
            if (arg.value === "a-z" || arg.value === "z-a") {
              delete selected.value.sort.firstName;
            } else if (arg.value === "hlp" || arg.value === "lhp") {
              delete selected.value.sort.pay;
            }
          }
        }
      }

      function getAllActiveFilters() {
        // check if active exist then remove it
        activeSortArray.value = activeSortArray.value.filter(
          (el) => el !== arg.name
        );

        const arr = sortOptions
          .filter((el) => el.active)
          .map((el) => {
            return el.name;
          });

        activeSortArray.value = arg.name == "All" ? [] : [...arr];
      }

      getAllActiveFilters();

      const activeArrays = [
        ...activeSortArray.value,
        ...activeFilterArray.value,
      ];

      activeFilter.value = arg.name;
      // emit("update", {
      //   filter: selected.value,
      //   active: activeArrays,
      // });
      // console.log("Sort");
      // console.log(selected.value);
    };

    const filterFunc = (parent, child, name) => {
      // unselect All
      sortOptions[0].active = false;

      const options = {
        compType: "comp.type",
        createdAt: "comp.date",
      };

      //check if options is available then remove if it is
      if (!checkIfOptionExist()) {
        removeOtherRelatedFilter(); // Remove every filter option from that section
        const active = toggleActiveState().active; // Toggle the active state and return the active

        if (active) {
          selected.value.filter.push({
            field: options[parent],
            value: child,
          });
        } else {
          const index = selected.value.filter
            .map((el) => el.value)
            .indexOf(child);
          selected.value.filter.pop(index);
        }
      } else {
        removeOtherRelatedFilter(); // Remove every filter option from that section
      }

      // Toggle the active state of the option
      function toggleActiveState() {
        //Get parent
        const parentIndex = filterOptions.map((el) => el.value).indexOf(parent);
        const parentObj = filterOptions[parentIndex];
        //console.log(parentObj);

        //Loop parent to find selected child
        const childIndex = parentObj.options
          .map((el) => el.value)
          .indexOf(child);
        const childObj = parentObj.options[childIndex];
        //console.log(childObj);

        childObj.active = !childObj.active;

        return {
          active: childObj.active,
          index: childIndex,
        };
      }

      function checkIfOptionExist() {
        //Get parent
        const parentIndex = filterOptions.map((el) => el.value).indexOf(parent);
        const parentObj = filterOptions[parentIndex];
        //console.log(parentObj);

        //Loop parent to find selected child
        const childIndex = parentObj.options
          .map((el) => el.value)
          .indexOf(child);
        const childObj = parentObj.options[childIndex];

        return childObj.active;
      }

      function removeOtherRelatedFilter() {
        //Get parent
        const parentIndex = filterOptions.map((el) => el.value).indexOf(parent);
        const parentObj = filterOptions[parentIndex];

        // set all the active state to fallse
        parentObj.options.forEach((el) => {
          el.active = false;
        });

        const newFilter = [...selected.value.filter];

        newFilter.forEach((el, index) => {
          if (el.field === options[parent]) {
            newFilter.pop(index);
          }
        });

        // console.log("New Filter");
        // console.log(newFilter);
        selected.value.filter = newFilter;
      }

      function getAllActiveFilters() {
        // check if active exist then remove it
        activeFilterArray.value = activeFilterArray.value.filter(
          (el) => el !== name
        );

        const arr = filterOptions
          .flatMap((option) => option.options.filter((child) => child.active))
          .map((el) => {
            return el.name;
          });

        activeFilterArray.value = [...arr];
      }

      getAllActiveFilters();

      const activeArrays = [
        ...activeSortArray.value,
        ...activeFilterArray.value,
      ];

      activeFilter.value = name;
      // emit("update", {
      //   filter: selected.value,
      //   active: activeArrays,
      // });

      // console.log("Filter");
      // console.log(selected.value);
    };

    const removeFilter = (arg) => {
      let selectedFilter = null;

      //Get the Filter or Sort Object using the name
      let sort = sortOptions.find((el) => el.name == arg);
      if (sort) {
        selectedFilter = sort;
      } else {
        filterOptions.forEach((parent) => {
          let child = parent.options.find((el) => el.name == arg);
          if (child) {
            selectedFilter = { parent, child };
          }
          if (selectedFilter) return;
        });
      }

      //Remove the Filter and query employee
      if (selectedFilter) {
        if (selectedFilter.sort) {
          sortFunc(selectedFilter);
        } else {
          const { parent, child } = selectedFilter;
          filterFunc(parent.value, child.value, child.name);
        }
      }

      const arr = [...activeSortArray.value, ...activeFilterArray.value];
      activeFilter.value = arr[arr.length - 1];
      //
    };

    function updateType(key) {
      filterData.value.names = []; //use one filter type at a time
      let index = filterData.value.names.indexOf(dedsTable[key]);
      if (index > -1) {
        filterData.value.names.splice(index, 1);
      } else {
        filterData.value.names.push(dedsTable[key]);
      }
    }

    return {
      toggleFilter,
      sortOptions,
      activeFilter,
      filterOptions,
      filterFunc,
      sortFunc,
      removeFilter,
      updateType,
      emitFilter,
      resetFilter,

      statutoryDeds,
      filterData,
      dedsTable,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.checkDropdownPosition);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkDropdownPosition);
  },
  methods: {
    checkDropdownPosition() {
      let dropdown = this.$el;
      let windowHeight = window.innerHeight;
      let dropdownRect = dropdown.getBoundingClientRect();
      let dropdownHeight = dropdownRect.height;
      let dropdownTop = dropdownRect.top;
      let dropdownBottom = dropdownRect.bottom;

      if (dropdownTop < 0) {
        this.dropdownStyle = "top-0";
      } else if (dropdownBottom > 210) {
        this.dropdownStyle = "bottom-0";
      } else {
        this.dropdownStyle = "top-0";
      }
    },
  },
};
</script>

<style scoped>
.mod {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 19, 9, 0.8);
}
</style>
