<template>
  <main class="pt-8 w-full">
    <form
      autocomplete="on"
      @submit.prevent="registerItf"
      class="flex flex-col w-full text-left"
    >
      <h2
        class="font-bold text-center mb-5 text-base md:text-2xl text-dark-800"
      >
        ITF Registration
      </h2>
      <div class="px-3 md:px-8 lg:px-6 xl:px-8">
        <div class="flex flex-col items-center gap-2 mb-6">
          <span class="text-dark-800 text-xs font-bold mb-1"
            >Download our 7A form below and populate it.</span
          >

          <div
            @click="download()"
            class="w-56 text-center mt-3 shadow-sm rounded-full bg-newLimeGreen border border-outlineGray py-2 px-4 cursor-pointer"
          >
            <!-- <span class="text-secondary text-sm text-center font-medium">
          <i class="fas fa-download mr-3"></i>Download Template</span
        > -->

            <span
              class="w-full flex items-center gap-3 justify-center text-primary text-base text-center font-bold"
            >
              <span>Download Form</span>
              <img src="@/assets/icons/download-icon2.svg" alt="" />
            </span>
          </div>
        </div>
        <hr
          class="mb-4 w-full border-0.5 border-dividerColor text-dividerColor"
        />
      </div>

      <div class="flex flex-col gap-3 px-3 md:px-10 lg:px-6 xl:px-8 mb-3">
        <div
          class="py-4 px-4 border border-outlineGray rounded-2xl bg-newGrey flex items-center justify-between"
        >
          <div class="flex items-center gap-2">
            <div
              class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0"
            >
              <img src="@/assets/icons/pdf-icon.svg" alt="" />
            </div>
            <div class="flex flex-col leading-4">
              <div
                v-if="uploadFile && uploadFile.name"
                class="text-center w-9/12 font-bold text-sm flex-col leading-4"
              >
                {{ uploadFile.name }}
              </div>

              <span v-else class="mb-2 text-sm font-bold"
                >Upload the populated 7A form here</span
              >
              <!-- <div
              v-else-if="file.uploadFile && !file.uploadFile.name"
              class="text-center font-bold text-sm flex-col leading-4"
            >
              {{ getLink(file.uploadFile) }}
            </div> -->
            </div>
          </div>

          <!-- <GetStatus v-if="status" :status="'Verified'" :showBorder="false" /> -->
          <span class="bg-white rounded-full">
            <UploadComponent
              :label="'Upload'"
              :showBorder="false"
              :loop="true"
              @fileUrl="uploadFile = $event"
            />
          </span>
        </div>
        <div
          class="py-4 px-4 border border-outlineGray rounded-2xl bg-newGrey flex items-center justify-between"
        >
          <div class="flex items-center gap-2">
            <div
              class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0"
            >
              <img src="@/assets/icons/pdf-icon.svg" alt="" />
            </div>
            <div class="flex flex-col leading-4">
              <div
                v-if="uploadCac && uploadCac.name"
                class="text-center w-9/12 font-bold text-sm flex-col leading-4"
              >
                {{ uploadCac.name }}
              </div>

              <span v-else class="mb-2 text-sm font-bold"
                >Upload CAC Document (Jpeg and Pdf Only) (10mb max)</span
              >
              <!-- <div
              v-else-if="file.uploadFile && !file.uploadFile.name"
              class="text-center font-bold text-sm flex-col leading-4"
            >
              {{ getLink(file.uploadFile) }}
            </div> -->
            </div>
          </div>

          <!-- <GetStatus v-if="status" :status="'Verified'" :showBorder="false" /> -->
          <span class="bg-white rounded-full">
            <UploadComponent
              :label="'Upload'"
              :showBorder="false"
              :loop="true"
              @fileUrl="uploadCac = $event"
            />
          </span>
        </div>

        <div
          class="py-4 px-4 border border-outlineGray rounded-2xl bg-newGrey flex items-center justify-between"
        >
          <div class="flex items-center gap-2">
            <div
              class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0"
            >
              <img src="@/assets/icons/pdf-icon.svg" alt="" />
            </div>
            <div class="flex flex-col leading-4">
              <div
                v-if="uploadReport && uploadReport.name"
                class="text-center w-9/12 font-bold text-sm flex-col leading-4"
              >
                {{ uploadReport.name }}
              </div>

              <span v-else class="mb-2 text-sm font-bold"
                >Upload audited financial report from inception of the company
                (Jpeg and Pdf Only) (10mb max)</span
              >
              <!-- <div
              v-else-if="file.uploadFile && !file.uploadFile.name"
              class="text-center font-bold text-sm flex-col leading-4"
            >
              {{ getLink(file.uploadFile) }}
            </div> -->
            </div>
          </div>

          <!-- <GetStatus v-if="status" :status="'Verified'" :showBorder="false" /> -->
          <span class="bg-white rounded-full">
            <UploadComponent
              :label="'Upload'"
              :showBorder="false"
              :loop="true"
              @fileUrl="uploadReport = $event"
            />
          </span>
        </div>

        <div
          class="py-4 px-4 border border-outlineGray rounded-2xl bg-newGrey flex items-center justify-between"
        >
          <div class="flex items-center gap-2">
            <div
              class="h-12 w-12 flex justify-center items-center bg-white rounded-full flex-shrink-0"
            >
              <img src="@/assets/icons/pdf-icon.svg" alt="" />
            </div>
            <div class="flex flex-col leading-4">
              <div
                v-if="uploadTaxCert && uploadTaxCert.name"
                class="text-center w-9/12 font-bold text-sm flex-col leading-4"
              >
                {{ uploadTaxCert.name }}
              </div>

              <span v-else class="mb-2 text-sm font-bold"
                >Upload at least 3 years tax clearance certificate (Jpeg and Pdf
                Only) (10mb max)</span
              >
              <!-- <div
              v-else-if="file.uploadFile && !file.uploadFile.name"
              class="text-center font-bold text-sm flex-col leading-4"
            >
              {{ getLink(file.uploadFile) }}
            </div> -->
            </div>
          </div>

          <!-- <GetStatus v-if="status" :status="'Verified'" :showBorder="false" /> -->
          <span class="bg-white rounded-full">
            <UploadComponent
              :label="'Upload'"
              :showBorder="false"
              :loop="true"
              @fileUrl="uploadTaxCert = $event"
            />
          </span>
        </div>
      </div>

      <hr
        class="my-3 mx-auto w-10/12 border-0.5 border-dividerColor text-dividerColor"
      />
      <div class="px-3 md:px-12 lg:px-8 xl:px-10">
        <button
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Submit
        </button>
      </div>
    </form>

    <easiModal v-if="registerForCode" @close="registerForCode = false">
      <template v-slot:header
        ><h1 class="text-dark-800 md:text-base text-sm font-bold">
          Register for Employer Code
        </h1></template
      >
      <form autocomplete="on" class="flex flex-col text-left">
        <div class="px-3 md:px-12 lg:px-8 xl:px-10">
          <div class="w-full flex flex-col mb-5">
            <div class="justify-center mb-8">
              <UploadComponent
                @fileUrl="uploadFile = $event"
                :pill="true"
                :tiny="true"
                :label="'Choose File'"
                :info="'Upload CAC Document (Jpeg and Pdf Only) (10mb max)'"
              />
            </div>

            <div class="justify-center mb-8">
              <UploadComponent
                @fileUrl="uploadFile = $event"
                :pill="true"
                :tiny="true"
                :label="'Choose File'"
                :info="'Upload MEMART (Jpeg and Pdf Only) (10mb max)'"
              />
            </div>

            <label for="tin" class="text-dark-800 text-sm font-medium"
              >TIN
            </label>
            <easiTextInput
              @input="updateArgs"
              placeholder="TIN"
              type="text"
              name="tin"
              class="mt-1 mb-8"
              required
            ></easiTextInput>
          </div>
        </div>
        <hr class="mb-5 border-0.5 border-dividerColor text-dividerColor" />
        <div class="px-3 md:px-12 lg:px-8 xl:px-10">
          <button
            class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
          >
            Submit
          </button>
        </div>
      </form>
    </easiModal>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>{{ successMessage }}</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import axios from "axios";

import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import IndustryList from "@/utils/Industry";
import HmoProviders from "@/utils/HmoProviders";

import UploadComponent from "@/components/global/UploadComponent";
import NigerianStates from "@/utils/states";
import lgData from "@/utils/localGovt";
import Countries from "@/utils/countries";

import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

const props = defineProps({
  goBack: {
    type: Boolean,
    default: false,
  },
});

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { processDate, uploadFileToServer } = helperFunctions;
const emit = defineEmits(["registered", "back"]);

const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

const isChosen = ref(false);
const showOptions = ref(true);
const regType = ref("");

const watchProp = computed(() => props.goBack);
const backBool = ref(false);

watch(watchProp, (val) => {
  if (val) {
    showOptions.value = true;
  }
  backBool.value = val;
});

function queryEmployees() {
  query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
queryEmployees();

const successMessage = ref("");
const updateSuccess = ref("");
const loading = ref(false);

const route = useRoute();
const employer = ref(true);
const industryData = IndustryList.map((el) => el.name);
const uploadFile = ref(null);
const uploadCac = ref(null);
const uploadReport = ref(null);
const uploadTaxCert = ref(null);
const localGovtData = ref([]);
// const selectedState = computed(() => args.value.state);
const agreement = ref(true);
const useTaxOffice = ref(true);
const isExpatriate = ref(false);
const hasTaxPayerId = ref(false);
const registerForCode = ref(false);
const btnState = ref("primary");
const args = ref({
  urls: [],
});
const allEmployees = computed(() => getAllEmployees.value.data);

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
const countryData = computed(() => {
  let countryArray = [];
  countryArray = Countries.map((country) => {
    return country.name;
  });
  return countryArray;
});

function closeFN() {
  emit("registered");
}
async function registerItf() {
  loading.value = true;

  let payload = {};

  if (uploadFile.value) {
    const file = await uploadFileToServer(uploadFile.value);
    if (file) {
      args.value.urls.push(file);
    } else {
      toast.error("Form upload failed");
      return;
    }
  }
  if (uploadCac.value) {
    const cac = await uploadFileToServer(uploadCac.value);
    if (cac) {
      args.value.urls.push(cac);
    } else {
      toast.error("CAC document upload failed");
      return;
    }
  }

  if (uploadReport.value) {
    const report = await uploadFileToServer(uploadReport.value);
    if (report) {
      args.value.urls.push(report);
    } else {
      toast.error("Financial Report upload failed");
      return;
    }
  }

  if (uploadTaxCert.value) {
    const taxCert = await uploadFileToServer(uploadTaxCert.value);
    if (taxCert) {
      args.value.urls.push(taxCert);
    } else {
      toast.error("Tax Certificate upload failed.");
      return;
    }
  }
  // args.proofOfAddress = await uploadFileToServer(uploadProofOfAddress.value);
  const keys = Object.keys(args.value);
  if (args.value && args.value.urls && args.value.urls.length) {
    for (let k of keys) {
      if (args.value[k].length) {
        payload[k] = args.value[k];
      }
    }
  } else {
    toast.error("Upload Failed");
    return false;
  }

  try {
    let res = await mutate({
      endpoint: "RegisterItfCompliance",
      data: { input: payload },
      service: "PAYROLL",
    });
    if (res.success) {
      console.log(res);
      successMessage.value = res.message;
      // removeFiles.value = true;
      // uploadFile.value = null;
      // uploadProofOfAddress.value = null;
      loading.value = false;
      // await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        closeFN();
      }, 1000);
    } else {
      loading.value = false;
      toast.error(res.message);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
    loading.value = false;
  }
}
// watch(
//   selectedState,
//   (value, newValue) => {
//     console.log(value, "New Value");
//     const lgs = lgData.find(
//       (lg) => lg.state.toLowerCase() === value.toLowerCase()
//     );
//     localGovtData.value = lgs.lgas.map((lg) => {
//       return {
//         label: lg,
//         value: lg,
//       };
//     });
//   },
//   { deep: true }
// );
const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  console.log(stateArray);
  return stateArray;
});
const today = ref("");
today.value = processDate();

function updateLg(event) {
  console.log(event);
  args.value.state = event;
}

function download() {
  btnState.value = "disabled";
  let host = window.location.protocol + "//" + window.location.host;
  axios({
    url: `${host}/uploads/ITF_Form_7A.pdf`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    var fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", "ITF_Form_7A.pdf");
    document.body.appendChild(fileLink);
    fileLink.click();
    btnState.value = "outline";
  });
}

function handleType(type) {
  showOptions.value = false;
  type === "company" ? (employer.value = true) : (employer.value = false);

  isChosen.value = true;
  regType.value = type;
  backBool.value = false;
  emit("back", backBool.value);
}
</script>

<style>
.wallet {
  background: linear-gradient(89.6deg, #0a244f 0.35%, #c92f02 268.44%);
}
</style>
